import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../../components/InnerPage';

const DieGemeinschaftZitate = () => (
    <InnerPage
        title="Zitate: Die Gemeinschaft"
    >
        <IntroHolder
            title="Zitate"
            subtitle="Die Gemeinschaft"
            breadcrumbs={{
                greatgrandparent: 'Woran Bahá’í glauben',
                grandparent: 'Der Einzelne und die Gesellschaft',
                parent: 'Die Gemeinschaft',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Ährenlese, 117">
                    Das Höchste Wesen spricht: Selig und glücklich ist, wer sich
                    erhebt, dem Wohle aller Völker und Geschlechter der Erde zu
                    dienen. An anderer Stelle hat Er verkündet: Es rühme sich
                    nicht, wer sein Vaterland liebt, sondern wer die ganze Welt
                    liebt. Die Erde ist nur ein Land, und alle Menschen sind
                    seine Bürger.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá zitiert in Shoghi Effendi, Der verheissene Tag ist gekommen, 31:4">
                    „In jeder Sendung wurde das Licht göttlicher Führung im
                    Brennpunkt einer Hauptaufgabe gesammelt … In dieser
                    wunderbaren Offenbarung, diesem glorreichen Jahrhundert, ist
                    die Grundlage der Religion Gottes und das unterscheidende
                    Merkmal Seines Gesetzes das Bewußtsein der Einheit der
                    Menschheit.“
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Brief an den Sohn des Wolfes, 1:20">
                    Ihr seid die Früchte eines Baumes und die Blätter eines
                    Zweiges. Verkehret miteinander in größter Liebe und
                    Eintracht, in Freundschaft und Brüderlichkeit. Er, die Sonne
                    der Wahrheit, ist Mein Zeuge! So mächtig ist das Licht der
                    Einheit, daß es die ganze Erde erleuchten kann.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Ansprachen in Paris, #9">
                    Die große selbstlose Liebe zur Menschheit ist durch keine
                    dieser unvollkommenen, selbstsüchtigen Bindungen gefesselt.
                    Sie ist die einzige vollkommene Liebe, die allen Menschen
                    möglich und nur durch die Macht des göttlichen Geistes zu
                    erreichen ist. Keine weltliche Macht kann die allumfassende
                    Liebe je zustande bringen. Lasst alle in dieser göttlichen
                    Macht der Liebe eins sein! Lasst alle danach streben, dass
                    sie im Lichte der Sonne der Wahrheit wachsen und diese
                    strahlende Liebe auf alle Menschen widerspiegeln, damit ihre
                    Herzen geeinigt werden und sie immerdar im Glanze dieser
                    grenzenlosen Liebe bleiben…Wenn ihr ein Glied eurer Familie
                    oder einen Landsmann liebt, so tut es mit einem Strahl der
                    unendlichen Liebe! Tut es mit Gott und für Gott! Wo immer
                    ihr die Eigenschaften Gottes findet, liebt jenen Menschen,
                    gleichviel, ob er zu eurer Familie oder zu einer anderen
                    zählt. Ergießet das Licht der grenzenlosen Liebe über jedes
                    menschliche Wesen, das ihr antrefft, mag es gleich eurem
                    Lande, eurer Rasse, eurer politischen Partei oder
                    irgendeiner anderen Nation, Farbe oder politischen Richtung
                    angehören. Der Himmel wird euch helfen, wenn ihr daran
                    arbeitet, die zerstreuten Völker der Welt unter den Schatten
                    des allmächtigen Zeltes der Einigkeit zu sammeln.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieGemeinschaftZitate;
